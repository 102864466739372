import { AuthProvider } from '@redwoodjs/auth'
import { Auth0Client } from '@auth0/auth0-spa-js'
import ReactDOM from 'react-dom'
import { RedwoodProvider } from '@redwoodjs/web'
import * as Sentry from '@sentry/browser'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import FatalErrorBoundary from 'src/components/FatalErrorBoundary'

import Routes from 'src/Routes'

import './index.css'

const auth0 = new Auth0Client({
  domain: process.env.REDWOOD_ENV_AUTH0_DOMAIN,
  client_id: process.env.REDWOOD_ENV_AUTH0_CLIENT_ID,
  redirect_uri: process.env.REDWOOD_ENV_AUTH0_REDIRECT_URI,
  cacheLocation: 'localstorage',
  audience: process.env.REDWOOD_ENV_AUTH0_AUDIENCE,
})

if (process.env.REDWOOD_ENV_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REDWOOD_ENV_SENTRY_DSN,
  })
}

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <AuthProvider client={auth0} type="auth0">
      <RedwoodProvider>
        <Routes />
      </RedwoodProvider>
    </AuthProvider>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
